<template>
	<div class="distressalertsFormHeader puiformheader">
		<v-row dense>
			<v-col class="col-12 col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.distressalerts.distressid')" :value="getDistressidValue"></pui-field>
			</v-col>

			<v-col class="col-12 col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.distressalerts.statusdesc')" :value="getStatusdescValue"></pui-field>
			</v-col>

			<v-col class="col-12 col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.distressalerts.typedesc')" :value="getTypedescValue"></pui-field>
			</v-col>
			<v-col class="col-12 col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.distressalerts.comsourcedesc')" :value="getComsourcedescValue"></pui-field>
			</v-col>
			<v-col class="col-12 col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.distressalerts.vesselname')" :value="getVesselnameValue"></pui-field>
			</v-col>
			<v-col class="col-12 col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.distressalerts.imo')" :value="getImoValue"></pui-field>
			</v-col>
			<v-col class="col-12 col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.distressalerts.callsign')" :value="getCallsignValue"></pui-field>
			</v-col>
			<v-col class="col-12 col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3">
				<pui-field :label="$t('header.distressalerts.flag')" :value="getFlagValue"></pui-field>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import PuiFormHeaderMixin from 'pui9-mixins/PuiFormHeaderMixin';

export default {
	name: 'distressalerts-form-header',
	mixins: [PuiFormHeaderMixin],
	data() {
		return {
			modelName: 'distressalerts'
		};
	},
	computed: {
		getDistressidValue() {
			return this.model && this.model.distressid ? this.model.distressid : '-';
		},
		getVesselnameValue() {
			return this.model && this.model.vesselname ? this.model.vesselname : '-';
		},
		getTypedescValue() {
			return this.model && this.model.typedesc ? this.model.typedesc : '-';
		},
		getComsourcedescValue() {
			return this.model && this.model.comsourcedesc ? this.model.comsourcedesc : '-';
		},
		getStatusdescValue() {
			return this.model && this.model.statusdesc ? this.model.statusdesc : '-';
		},
		getImoValue() {
			return this.model && this.model.imo ? this.model.imo : '-';
		},
		getCallsignValue() {
			return this.model && this.model.callsign ? this.model.callsign : '-';
		},
		getFlagValue() {
			return this.model && this.model.flag ? this.model.flag : '-';
		}
	}
};
</script>
